'use client';

import { useEffect, useState } from 'react';

export const useIsMobileDevice = () => {
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        setIsMobileDevice(
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        );
    }, []);

    return isMobileDevice;
};
