import { FC } from 'react';
import Skeleton from '@/components/ui/Skeleton';
export interface Props {
  message: string | string[];
  variant?: 'success' | 'warning' | 'danger' | 'info';
  loading?: boolean;
  allowHtml?: boolean;
}
interface IButtonClass {
  name: string;
  style: string;
}
const buttonClasses: Array<IButtonClass> = [{
  name: 'success',
  style: 'text-green-700 rounded border-green-900/10 bg-green-50'
}, {
  name: 'warning',
  style: 'text-amber-700 bg-amber-50 border-amber-900/10'
}, {
  name: 'danger',
  style: 'text-red-700 border border-red-900/10 bg-red-50'
}, {
  name: 'info',
  style: 'text-sky-700 bg-sky-50 border-sky-900/10'
}];
const Alert: FC<Props> = ({
  message,
  variant = 'info',
  loading = false,
  allowHtml = false
}) => {
  const styling: IButtonClass | undefined = buttonClasses.find(classes => classes.name === variant);
  return <div className={`p-4 border rounded ${styling?.style}`} role="alert" data-sentry-component="Alert" data-sentry-source-file="Alert.tsx">
            {loading ? <Skeleton width={200} height={20} /> : Array.isArray(message) ? <ul className="list-disc pl-4 text-sm font-medium">
                    {message.map((msg, index) => <li key={index}>{msg}</li>)}
                </ul> : allowHtml ? <div className="text-sm font-medium [&_a]:underline [&_a]:text-blue-600 hover:[&_a]:text-blue-800" dangerouslySetInnerHTML={{
      __html: message
    }} /> : <strong className="text-sm font-medium">{message}</strong>}
        </div>;
};
export default Alert;