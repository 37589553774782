import(/* webpackMode: "eager" */ "/home/runner/work/app-frontend/app-frontend/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/app-frontend/app-frontend/node_modules/.pnpm/next@14.2.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/utils/font.ts\",\"import\":\"PT_Serif\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\"],\"style\":[\"normal\"],\"variable\":\"--font-serif\"}],\"variableName\":\"serifFont\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/app-frontend/app-frontend/node_modules/.pnpm/next@14.2.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/utils/font.ts\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\"],\"style\":[\"normal\"],\"variable\":\"--font-sans\"}],\"variableName\":\"sansFont\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/app-frontend/app-frontend/node_modules/.pnpm/next@14.2.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/utils/font.ts\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\"],\"style\":[\"normal\"],\"variable\":\"--font-mono\"}],\"variableName\":\"monoFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/app-frontend/app-frontend/src/components/ui/MobileWarning/MobileWarning.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/home/runner/work/app-frontend/app-frontend/src/contexts/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider"] */ "/home/runner/work/app-frontend/app-frontend/src/contexts/ToastContext.tsx");
