'use client';

import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { cn } from '@/utils/helper';
interface ToastProps {
  message: string;
  type?: 'success' | 'error' | 'warning';
}
const iconClasses = {
  success: 'text-green-400',
  error: 'text-red-400',
  warning: 'text-yellow-400'
};
const borderClasses = {
  success: 'border-green-400',
  error: 'border-red-400',
  warning: 'border-yellow-400'
};
const icons = {
  success: CheckCircleIcon,
  error: ExclamationCircleIcon,
  warning: ExclamationTriangleIcon
};
export default function Toast({
  message,
  type = 'success'
}: ToastProps) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 50); // Delay to allow for the initial render
    return () => clearTimeout(timer);
  }, []);
  const Icon = icons[type];
  const iconClass = iconClasses[type];
  const borderClass = borderClasses[type];
  return <Transition show={show} enter="transform transition ease-out duration-300" enterFrom="-translate-y-full" enterTo="translate-y-0" leave="transform transition ease-in duration-100" leaveFrom="translate-y-0" leaveTo="-translate-y-full" data-sentry-element="Transition" data-sentry-component="Toast" data-sentry-source-file="Toast.tsx">
            <div className={cn(`z-20 bg-white fixed top-10 right-10 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 border-l-2`, borderClass)}>
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            <Icon className={cn('h-6 w-6', iconClass)} aria-hidden="true" data-sentry-element="Icon" data-sentry-source-file="Toast.tsx" />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">
                                {type.charAt(0).toUpperCase() + type.slice(1)}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">{message}</p>
                        </div>
                        <div className="ml-4 flex flex-shrink-0">
                            <button type="button" className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => setShow(false)}>
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" data-sentry-element="XMarkIcon" data-sentry-source-file="Toast.tsx" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>;
}