'use client';

import { forwardRef } from 'react';
import Activity from '@/svg/Activity.svg';
import AlertCircle from '@/svg/AlertCircle.svg';
import Arrow from '@/svg/ArrowIcon.svg';
import AssessmentDisc from '@/svg/AssessmentDisc.svg';
import AssessmentEnneagram from '@/svg/AssessmentEnneagram.svg';
import AssessmentHumanDesign from '@/svg/AssessmentHumanDesign.svg';
import AssessmentLoveLanguage from '@/svg/AssessmentLoveLanguage.svg';
import AssessmentMbti from '@/svg/AssessmentMbti.svg';
import AssessmentStrengthFinder from '@/svg/AssessmentStrengthFinder.svg';
import Briefcase from '@/svg/Briefcase.svg';
import CloseCircle from '@/svg/CloseCircle.svg';
import Edit from '@/svg/Edit.svg';
import Graph1 from '@/svg/Graph1.svg';
import Graph2 from '@/svg/Graph2.svg';
import LogoIcon from '@/svg/LogoIcon.svg';
import Message from '@/svg/Message.svg';
import Share from '@/svg/Share.svg';
import Users from '@/svg/Users.svg';
import Zap from '@/svg/Zap.svg';
import { cn } from '@/utils/helper';
export const iconRegistry = {
  Activity,
  AlertCircle,
  Arrow,
  AssessmentDisc,
  AssessmentEnneagram,
  AssessmentHumanDesign,
  AssessmentLoveLanguage,
  AssessmentMbti,
  AssessmentStrengthFinder,
  Briefcase,
  CloseCircle,
  Edit,
  Graph1,
  Graph2,
  LogoIcon,
  Message,
  Share,
  Users,
  Zap
};
export type IconNames = keyof typeof iconRegistry;
export interface IconProps {
  iconName: IconNames;
  isDecorative?: boolean;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  disableFill?: boolean;
}
const Icon = forwardRef<SVGSVGElement, IconProps>(function IconComponent({
  iconName,
  isDecorative = true,
  title,
  className,
  style,
  disableFill = false,
  ...rest
}, ref) {
  const SvgComponent = iconRegistry[iconName];
  if (!SvgComponent) {
    console.error(`Icon "${iconName}" not found.`);
    return null;
  }
  const accessibilityProps = isDecorative ? {
    'aria-hidden': 'true',
    role: 'presentation'
  } : {
    'aria-label': title,
    role: 'img'
  };
  if (!isDecorative && !title) {
    console.error('Non-decorative icons must have a title for accessibility.');
    return null;
  }
  return <span className={cn('inline-block', className)}>
            <SvgComponent className={cn('w-full align-middle', !disableFill && 'fill-current')} ref={ref} {...accessibilityProps} style={style} {...rest} />
        </span>;
});
export default Icon;