'use client';

import Link from 'next/link';
import Icon, { IconNames } from '@/components/ui/Icon';
import { cn } from '@/utils/helper';
type ButtonVariant = 'primary' | 'primary--white' | 'secondary' | 'secondary--white' | 'tertiary' | 'danger';
type ButtonSize = 'small' | 'large';
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  loading?: boolean;
  iconFront?: IconNames;
  iconBack?: IconNames;
  rotateFront?: number;
  rotateBack?: number;
  href?: string;
}
const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled = false,
  loading = false,
  variant = 'primary',
  size = 'large',
  fullWidth = false,
  type = 'button',
  className = '',
  iconFront,
  iconBack,
  rotateFront = 0,
  rotateBack = 0,
  href,
  ...rest
}) => {
  const baseClasses = 'flex justify-center font-sans font-bold px-6 py-2.5 shadow-none items-center leading-none transition-colors duration-200 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring';
  const variantClasses: Record<ButtonVariant, string> = {
    primary: 'bg-gray-333 border border-black text-neutral-100 hover:bg-black focus:ring-black hover:text-white',
    'primary--white': 'bg-white border border-gray-484848 text-gray-484848 hover:text-black hover:border-black focus:ring-black',
    secondary: 'bg-transparent text-gray-484848 border border-black hover:bg-black-150 hover:text-black',
    'secondary--white': 'bg-transparent text-white border border-white hover:bg-black-150 hover:text-black',
    tertiary: 'bg-transparent text-gray-484848 border border-transparent hover:underline hover:text-black',
    danger: 'bg-red-500 text-white hover:bg-red-600 focus:ring-red-500'
  };
  const sizeClasses: Record<ButtonSize, string> = {
    small: 'text-base leading-none font-medium rounded',
    large: 'text-xl leading-none font-semibold rounded'
  };
  const widthClass = fullWidth ? 'w-full' : '';
  const disabledClasses = disabled || loading ? 'opacity-50 cursor-not-allowed' : '';
  const iconColor = variant === 'primary' ? 'fill-white' : variant === 'primary--white' ? 'fill-black' : '';
  const spinnerColor = variant === 'primary' ? '#fff' : variant === 'danger' ? '#fff' : variant === 'primary--white' ? '#000' : '#000';
  const combinedClasses = cn(baseClasses, variantClasses[variant], sizeClasses[size], widthClass, disabledClasses, className);
  const spinner = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 50 50" xmlSpace="preserve" className="animate-spin">
            <path fill={spinnerColor} d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068
                c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite" />
            </path>
        </svg>;
  if (href) {
    return <Link href={href} className={combinedClasses}>
                {loading && <div className="mr-2">{spinner}</div>}
                {iconFront && <Icon iconName={iconFront} className={cn('mr-2 w-5 h-5', iconColor)} isDecorative style={{
        transform: `rotate(${rotateFront}deg)`
      }} />}
                {children}
                {iconBack && <Icon iconName={iconBack} className={cn('ml-2 w-4 h-4', iconColor)} isDecorative style={{
        transform: `rotate(${rotateBack}deg)`
      }} />}
            </Link>;
  }
  return <button type={type} className={combinedClasses} onClick={onClick} disabled={disabled || loading} {...rest} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
            {loading && <div className="mr-2">{spinner}</div>}
            {iconFront && <Icon iconName={iconFront} className={cn('mr-2 w-5 h-5', iconColor)} isDecorative style={{
      transform: `rotate(${rotateFront}deg)`
    }} />}
            {children}
            {iconBack && <Icon iconName={iconBack} className={cn('ml-2 w-4 h-4', iconColor)} isDecorative style={{
      transform: `rotate(${rotateBack}deg)`
    }} />}
        </button>;
};
export default Button;