import Cookies from 'js-cookie';

import { storeUserTokens } from '@/app/actions';
import { AUTH_TOKEN, SSR_USER } from '@/constants/base.constant';
import { FetchResultState, Token } from '@/types/fetch';
import { User } from '@/types/user';
import { authenticatedFetch, setupFetchOptions } from '@/utils/api';

export interface LoginResponseData {
    user: User;
    token: Token;
}

type LoginResponse = FetchResultState<LoginResponseData>;

export const loginUser = async (
    email: string,
    password: string,
    rememberMe: number,
): Promise<LoginResponse> => {
    const options = await setupFetchOptions('POST', {
        email,
        password,
        remember_me: rememberMe,
    });

    const result = await authenticatedFetch<LoginResponseData>(`/login`, options);
    await storeUserTokens(result?.response?.data);
    return result;
};

export const logoutUser = async (): Promise<FetchResultState<any>> => {
    if (!Cookies.get(AUTH_TOKEN)) {
        await removeUserTokens();
        return { response: null, errors: null, loading: false };
    }
    const options = await setupFetchOptions('POST');
    const results = await authenticatedFetch<any>('/logout', options);
    await removeUserTokens();
    return results;
};

export interface tokenObject {
    token: string;
    expires_at: string;
}
export interface dataObject {
    user?: User;
    token?: tokenObject;
}

export const removeUserTokens = async (): Promise<void> => {
    Cookies.remove(AUTH_TOKEN);
    Cookies.remove(SSR_USER);
    return;
};
