import Button from '@/components/ui/Button';
import Typography from '@/components/ui/Typography';
import { cn } from '@/utils/helper';
interface BlurSectionProps {
  heading: string;
  children: React.ReactNode;
  onClick?: () => void;
  ctaLabel?: string;
  isFixed?: boolean;
  [key: string]: any;
}
const BlurSection: React.FC<BlurSectionProps> = ({
  children = null,
  heading,
  ctaLabel,
  onClick,
  isFixed = false,
  ...props
}) => {
  return <div className={cn(isFixed ? 'fixed' : 'absolute', 'inset-0 bg-white bg-opacity-50 backdrop-blur-sm flex p-20 items-center justify-center z-10')} {...props} data-sentry-component="BlurSection" data-sentry-source-file="BlurSection.tsx">
            <div className="bg-white p-12 rounded-lg shadow-lg space-y-8 ">
                <h4 className={cn(`text-center text-gray-800 font-semibold ${Typography.h4}`)}>
                    {heading}
                </h4>
                {children && <div className="mt-4">{children}</div>}
                {ctaLabel && <div className="flex justify-center align-center">
                        <Button variant="primary" className="mt-4" onClick={onClick}>
                            {ctaLabel}
                        </Button>
                    </div>}
            </div>
        </div>;
};
export default BlurSection;