import 'react-loading-skeleton/dist/skeleton.css';
import SkeletonComponent, { SkeletonTheme } from 'react-loading-skeleton';
interface SkeletonProps {
  width?: number | string;
  height?: number | string;
  count?: number;
  circle?: boolean;
  baseColor?: string;
  highlightColor?: string;
  borderRadius?: string | number;
  duration?: number;
  direction?: 'ltr' | 'rtl';
  enableAnimation?: boolean;
  inline?: boolean;
  containerClassName?: string;
  containerTestId?: string;
}
const Skeleton: React.FC<SkeletonProps> = ({
  width = 300,
  height = 24,
  count = 1,
  circle = false,
  baseColor = '#ebebeb',
  highlightColor = '#f5f5f5',
  borderRadius = '0.25rem',
  duration = 1.5,
  direction = 'ltr',
  enableAnimation = true,
  inline = false,
  containerClassName,
  containerTestId
}) => {
  return <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor} data-sentry-element="SkeletonTheme" data-sentry-component="Skeleton" data-sentry-source-file="Skeleton.tsx">
            <SkeletonComponent width={width} height={height} count={count} circle={circle} borderRadius={borderRadius} duration={duration} direction={direction} enableAnimation={enableAnimation} inline={inline} containerClassName={containerClassName} containerTestId={containerTestId} data-sentry-element="SkeletonComponent" data-sentry-source-file="Skeleton.tsx" />
        </SkeletonTheme>;
};
export default Skeleton;