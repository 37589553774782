import { cn } from '@/utils/helper';

const baseHeadingStyles = 'font-serif leading-140 tracking-[-0.02em] block';
const baseParagraphStyles = 'font-sans leading-140 tracking-[-0.015em] inline-block';
const baseMonoStyles = 'font-mono leading-140 tracking-[-0.01em] inline-block';

export const Typography = {
    // Headings
    h1: cn('text-[48px] xl:text-[56px] 2xl:text-[64px]', baseHeadingStyles), // Base: 48px, XL: 56px, 2XL: 64px
    h2: cn('text-4xl xl:text-[44px] 2xl:text-5xl', baseHeadingStyles), // Base: 36px, XL: 44px, 2XL: 48px
    h3: cn('text-3xl xl:text-[36px] 2xl:text-4xl', baseHeadingStyles), // Base: 30px, XL: 36px, 2XL: 40px
    h4: cn('text-2xl xl:text-[28px] 2xl:text-[32px]', baseHeadingStyles), // Base: 24px, XL: 28px, 2XL: 32px
    h5: cn('text-xl xl:text-[22px] 2xl:text-2xl', baseHeadingStyles), // Base: 20px, XL: 22px, 2XL: 24px

    // Paragraphs
    p1: cn('text-base xl:text-[15px] 2xl:text-base', baseParagraphStyles), // Base: 16px, XL: 15px, 2XL: 16px
    p2: cn('text-base xl:text-lg 2xl:text-xl', baseParagraphStyles), // Base: 16px, XL: 18px, 2XL: 20px
    p3: cn('text-lg xl:text-xl 2xl:text-2xl', baseParagraphStyles), // Base: 18px, XL: 20px, 2XL: 24px
    p4: cn('text-xl xl:text-2xl 2xl:text-[32px]', baseParagraphStyles), // Base: 20px, XL: 24px, 2XL: 32px
    p5: cn('text-2xl xl:text-[32px] 2xl:text-[40px]', baseParagraphStyles), // Base: 24px, XL: 32px, 2XL: 40px

    // Mono Text
    m1: cn('text-base xl:text-[15px] 2xl:text-base', baseMonoStyles), // Base: 16px, XL: 15px, 2XL: 16px
    m2: cn('text-base xl:text-lg 2xl:text-xl', baseMonoStyles), // Base: 16px, XL: 18px, 2XL: 20px
    m3: cn('text-lg xl:text-xl 2xl:text-2xl', baseMonoStyles), // Base: 18px, XL: 20px, 2XL: 24px
    m4: cn('text-xl xl:text-2xl 2xl:text-[32px]', baseMonoStyles), // Base: 20px, XL: 24px, 2XL: 32px
};

export default Typography;
