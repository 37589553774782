'use client';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import InputGroup from '@/components/ui/Forms/InputGroup';
import ROUTES from '@/constants/routes.constant';
import Typography from '@/components/ui/Typography';
import { loginUser } from '@/services/auth.service';
import { useAuth } from '@/contexts/AuthContext';
const LoginForm = () => {
  const {
    user,
    loading: userLoading,
    mutate
  } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setError] = useState<string[] | null>(null);
  const router = useRouter();
  useEffect(() => {
    if (userLoading) {
      return;
    }
    if (user) {
      router.push(ROUTES.HOME.path);
    }
  }, [user, userLoading, router]);
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
  const handleRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked ? 1 : 0);
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const {
      errors
    } = await loginUser(email, password, rememberMe);
    if (errors) {
      setError(errors);
      setLoading(false);
      return;
    }
    mutate();
    window.location.href = ROUTES.HOME.path;
  };
  return <form className="space-y-6 text-white" onSubmit={handleSubmit} data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
            {errors && errors.length > 0 && <Alert message={errors[0]} variant="danger" />}

            <div>
                <InputGroup label="Email" type="email" name="email" id="email" value={email} onChange={handleEmailChange} required autoComplete="email" data-sentry-element="InputGroup" data-sentry-source-file="LoginForm.tsx" />
            </div>

            <div>
                <InputGroup label="Password" type="password" name="password" id="password" value={password} onChange={handlePasswordChange} required autoComplete="current-password" data-sentry-element="InputGroup" data-sentry-source-file="LoginForm.tsx" />
            </div>

            <div className="flex items-center text-white justify-between">
                <div className="flex items-center">
                    <input id="remember-me" name="remember_me" type="checkbox" checked={!!rememberMe} onChange={handleRememberMeChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <label htmlFor="remember-me" className={`ml-3 block ${Typography.m1} text-white`}>
                        Remember me
                    </label>
                </div>

                <div className="text-sm leading-6">
                    <a href={ROUTES.FORGOT_PASSWORD.path} className={`${Typography.m1} text-white`}>
                        Forgot password
                    </a>
                </div>
            </div>

            <div className="flex flex-col-reverse lg:flex-row justify-between gap-6">
                <Button variant="secondary--white" onClick={async () => {
        setLoading(true);
        await router.push(ROUTES.REGISTER.path);
      }} fullWidth disabled={loading} data-sentry-element="Button" data-sentry-source-file="LoginForm.tsx">
                    Create Account
                </Button>
                <Button type="submit" variant="primary--white" disabled={loading} loading={loading} fullWidth data-sentry-element="Button" data-sentry-source-file="LoginForm.tsx">
                    {loading ? 'Logging in...' : 'Log in'}
                </Button>
            </div>
        </form>;
};
export default LoginForm;