import Cookies from 'js-cookie';

import { AUTH_TOKEN } from '@/constants/base.constant';
import { FetchResultState } from '@/types/fetch';
import { User } from '@/types/user';
import api from '@/utils/api';

export interface MeResponseData {
    user: User;
}

export interface MeResponseData {
    user: User;
}

const useUserGetMe = (options?: { skip?: boolean }): FetchResultState<MeResponseData> => {
    const token = Cookies.get(AUTH_TOKEN);

    // Fetch the data using SWR
    const { response, errors, mutate } = api.get('/me', {}, {}, !token || options?.skip);

    return {
        response,
        errors: errors || null,
        loading: !response && !errors,
        mutate,
    };
};

export default useUserGetMe;
