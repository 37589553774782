'use client';

import { isEqual } from 'lodash';
import { usePathname } from 'next/navigation';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { PUBLIC_ROUTES } from '@/constants/routes.constant';
import { PLAN_IDS } from '@/constants/subscription.constant';
import useUserGetMe from '@/hooks/useUserGetMe';
import { User } from '@/types/user';
interface AuthContextProps {
  user: User | null;
  mutate: () => void;
  loading: boolean;
  errors: string[] | null;
  isAuthenticated: boolean;
  isVerified: boolean;
  isAdmin: boolean;
  auraInProgress: boolean;
}
export const AuthContext = createContext<AuthContextProps | undefined>(undefined);
export const AuthProvider = ({
  children,
  initialUser = null
}: {
  children: ReactNode;
  initialUser?: User | null;
}) => {
  const pathname = usePathname();

  // Match Middleware
  const isPublicRoute = useMemo(() => PUBLIC_ROUTES.some(route => pathname.startsWith(route)) || /^\/verify\/[^\/]+$/.test(pathname), [pathname]);
  const [user, setUser] = useState<User | null>(initialUser);
  const {
    response,
    mutate,
    loading,
    errors
  } = useUserGetMe({
    skip: isPublicRoute
  });

  // Derived values with memoization
  const isAuthenticated = !!user;
  const isVerified = useMemo(() => user?.email_verified ?? false, [user]);
  const isAdmin = useMemo(() => user?.role?.id === 1, [user]);
  const isStaff = useMemo(() => user?.role?.id === 2, [user]);
  const isMember = useMemo(() => user?.role?.id === 3, [user]);
  const auraStatus = useMemo(() => user?.aura?.batch?.status || 'completed', [user]);
  const isFreePlan = useMemo(() => user?.subscription?.plan === PLAN_IDS.FREE, [user]);
  const auraInProgress = useMemo(() => !loading && auraStatus !== 'completed' && auraStatus !== 'not_started', [loading, auraStatus]);
  const isAdminOrStaff = useMemo(() => isAdmin || isStaff, [isAdmin, isStaff]);

  // Update user state if response changes and the data is different
  useEffect(() => {
    if (!loading && response?.data?.user && !isEqual(response.data.user, user)) {
      setUser(response.data.user);
    }
  }, [response, user, loading]);
  const values = useMemo(() => ({
    user,
    mutate,
    loading,
    errors,
    isAuthenticated,
    isVerified,
    isAdmin,
    isAdminOrStaff,
    isStaff,
    isMember,
    auraInProgress,
    isFreePlan
  }), [user, mutate, loading, errors, isAuthenticated, isVerified, isAdmin, isAdminOrStaff, isStaff, isMember, isFreePlan, auraInProgress]);
  return <AuthContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="AuthContext.tsx">{children}</AuthContext.Provider>;
};
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};