'use client';

import { useEffect, useRef } from 'react';
const PulsingGradientCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    let animationFrameId: number;

    // Set canvas size to cover the viewport
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    // Initial resize
    resizeCanvas();

    // Handle window resize to keep canvas full-screen
    window.addEventListener('resize', resizeCanvas);

    // Gradient animation variables
    let movementX = 0;
    let movementY = 0;
    const drawGradient = () => {
      if (!ctx) return;

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Create a gradient with subtle movement
      movementX = Math.sin(Date.now() / 3000) * 100; // Horizontal movement
      movementY = Math.cos(Date.now() / 4000) * 100; // Vertical movement

      const gradient = ctx.createLinearGradient(0 + movementX, 0 + movementY, canvas.width - movementX, canvas.height - movementY);
      gradient.addColorStop(0, '#965455'); // 0%
      gradient.addColorStop(0.15, '#654A90'); // 15%
      gradient.addColorStop(0.39, '#4B7C63'); // 39%
      gradient.addColorStop(0.73, '#835174'); // 73%
      gradient.addColorStop(0.86, '#AFA339'); // 86%

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Request next frame
      animationFrameId = requestAnimationFrame(drawGradient);
    };
    drawGradient();

    // Cleanup on unmount
    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);
  return <>
            <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full" />
        </>;
};
export default PulsingGradientCanvas;